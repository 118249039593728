import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 14 15">
    <path 
      fill={color}
      d="M1.94784 8.0486C0.322083 6.32887 0.408305 3.60079 2.13559 1.96789C3.86288 0.334993 6.59606 0.397729 8.22182 2.11746C9.44436 3.41067 9.72483 5.33667 8.96086 6.92677C9.08377 6.96524 9.20556 7.03915 9.29993 7.13898C9.53157 7.384 9.5738 7.73075 9.43168 8.01978L13.6766 12.0066L13.6852 12.0157C14.0198 12.3697 13.9336 12.9925 13.4949 13.4072L12.9192 13.9515C12.4805 14.3662 11.8538 14.4173 11.5192 14.0633L11.5107 14.0543L7.73694 9.60472C7.44981 9.7129 7.11941 9.64716 6.89635 9.41121C6.80198 9.31139 6.73931 9.19018 6.70351 9.06077C5.07332 9.72541 3.17038 9.34181 1.94784 8.0486ZM3.47395 3.38361C2.53262 4.2735 2.48559 5.76154 3.36925 6.69627C4.25291 7.63101 5.74124 7.66759 6.68257 6.7777C7.62389 5.88781 7.67978 4.39999 6.79613 3.46525C5.91247 2.53052 4.41984 2.4894 3.47395 3.38361Z"
    />
  </svg>
);
